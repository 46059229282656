<template>
  <div id="Loading">
    <span>{{msg}}</span>
  </div>
</template>

<script>
import APIUtils from '@/utils/Network/APIUtils'
import {mapMutations} from 'vuex'
import router from "@/router";
import {EventBus} from "@/utils/EventBus";
export default {
  data() {
    return {
      msg : ""
    };
  },
  mounted() {
    this.msg = "로그인 정보 가져오는 중"
    this.loadingMyData().then(()=>{
        this.msg = "채팅방 정보 가져오는 중"
        this.loadingChatData().then(()=>{
          this.msg = "테마 데이터 가져오는 중"
          this.loadingThemeData().then(()=>{
            this.msg = "데이터 가공하는 중"
            this.setSaveSwc(true)
            this.$router.push({name : "Window"})
          })
        })
    })
  },
  methods: {
    loadingMyData(){
      const sToken = sessionStorage.getItem("kaloudToken")
      this.setAccessToken(sToken)
      return APIUtils.user_my_info()
          .then((res) => {
            this.$socket.emit('login', res.body.user_id)
            this.$socket.on('update', function(data){
              EventBus.$emit('bus:chatUpdate', data)
            })
            this.setMyData(res.body)
          })
          .catch((err) => {
            console.log("계정 정보를 불러오는데 실패했습니다.", err)
            this.delSaveData()
            router.push({ name: "Login" });
          });
    },
    loadingChatData(){
      return APIUtils.room_list().then((res)=>{
        this.setChatData(res.body)
      }).catch(err=>{
        console.log("채팅 정보를 불러오는데 실패했습니다.", err)
      })
    },
    loadingThemeData(){
      return APIUtils.common_theme().then(res=>{
        this.setThemeData(res.body)
      }).catch(err=>{
        console.log("테마 데이터를 불러오는데 실패했습니다.", err)
      })
    },
    sleep(ms) {
      const wakeUpTime = Date.now() + ms;
      while (Date.now() < wakeUpTime) {}
    },
    ...mapMutations({
      setMyData: 'SET_MY_DATA',
      setFolderData: 'SET_FOLDER_DATA',
      setChatData: 'SET_CHAT_DATA',
      setSaveSwc: 'SET_SAVE_SWC',
      delSaveData: 'DEL_SAVE_DATA',
      setAccessToken: 'SET_ACCESS_TOKEN',
      setThemeData : 'SET_THEME_DATA'
    }),
  },
};
</script>

<style scoped>
</style>
